import {
  mBTYC_Address,
  CONTRACT_ABI,
  CONTRACT_ADDRESS,
  TOKEN_ABI,
  New_Contract_Address,
  New_CONTRACT_ABI,
  Farming_ABI,
  Farming_Contract,
  TOKEN_ADDRESS,
  Sell_Btyc,
  Sell_ABI,
  Sell_mBtyc,
  Sell_mBtycABI,
} from "./config";
import { readContract, writeContract, fetchBalance ,multicall} from "@wagmi/core";

import { toast } from "react-hot-toast";
import { RefreshSatate } from "../store/actions/AuthActions";

const getAddress = {
  address: CONTRACT_ADDRESS,
  abi: CONTRACT_ABI,
};

const getNewConAddress = {
  address: New_Contract_Address,
  abi: New_CONTRACT_ABI,
};

const getFarmingContract = {
  address: Farming_Contract,
  abi: Farming_ABI,
};
const getBuyBackBtyc= {
  address: Sell_Btyc,
  abi: Sell_ABI,
};

const getBuyBackmBtyc= {
  address: Sell_mBtyc,
  abi: Sell_mBtycABI,
};
export async function getIdToAddress(id) {
  try {
    const data = await readContract({
      ...getAddress,
      functionName: "idToAddress",
      args: [id],
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getUserAllDetails(address) {
  try {
    const data = await readContract({
      ...getAddress,
      functionName: "users",
      args: [address],
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function isUserExist(address) {
  try {
    const data = await readContract({
      ...getAddress,
      functionName: "isUserExists",
      args: [address],
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getTokenPrice() {
  try {
    const data = await readContract({
      ...getNewConAddress,
      functionName: "mBTYCPrice",
      // args: [token_address],
    });

    const resdata = Number(data._hex) / 1e18;
    return resdata;
  } catch (error) {
    console.log(error);
  }
}

async function Stake_Methods(walletAddress, packageStaking, refralId) {
  console.log(walletAddress, packageStaking, refralId, "stake::::");
  const res = await writeContract({
    mode: "recklesslyUnprepared",
    ...getNewConAddress,
    functionName: "stakeWithRefferal",
    args: [packageStaking, refralId],
    overrides: {
      from: walletAddress,
      value: 0,
      // gasPrice: 110,
    },
  });
  return res;
}

export async function getBalanceUser(address, TOKEN_ADDRESS) {
  try {
    const balance = await fetchBalance({
      address: address,
      token: TOKEN_ADDRESS,
    });
    return balance;
  } catch (err) {
    console.log(err);
  }
}

export async function registerExt(
  walletAddress,
  packageStaking,
  refralId,
  navigate,
  dispatch,
  setRegLoading
) {
  try {
    setRegLoading(true);

    const getBtycPrice = await getTokenPrice();
    const getBalance = await getBalanceUser(walletAddress, mBTYC_Address);
    console.log(walletAddress, packageStaking, refralId);
    const res = await readContract({
      address: mBTYC_Address,
      abi: TOKEN_ABI,
      functionName: "allowance",
      args: [walletAddress, New_Contract_Address],
    });

    const allowance = Number(res._hex);
    const allowanceval = Number(
      (Number(packageStaking) / Number(getBtycPrice) + 0.02) * 1e18
    );

    console.log(getBalance?.formatted, allowanceval / 1e18, "allowanceval");

    if (getBalance?.formatted >= allowanceval / 1e18) {
      if (
        Number(allowance) >=
        Number(allowanceval.toLocaleString("fullwide", { useGrouping: false }))
      ) {
        const res = await Stake_Methods(
          walletAddress,
          Number(packageStaking * 1e18).toLocaleString("fullwide", {
            useGrouping: false,
          }),

          refralId
        );
        console.log(packageStaking, "packageStaking");
        const stakeToken = res?.wait();
        toast
          .promise(stakeToken, {
            loading: " Transaction is Pending....",
            success: (d) => {
              return "Transaction Successfully.";
            },
            error: (error) => {
              return " Transaction request failed.";
            },
          })
          .then((d) => {
            setRegLoading(false);
            // console.log(d, "dd");
            dispatch(RefreshSatate(true));
            navigate("/login");
          })
          .catch((e) => {
            setRegLoading(false);
            console.log("Error::", e);
          });
      } else {
        const res = await writeContract({
          mode: "recklesslyUnprepared",
          address: mBTYC_Address,
          abi: TOKEN_ABI,
          functionName: "approve",
          args: [
            New_Contract_Address,
            allowanceval
              .toLocaleString("fullwide", { useGrouping: false })
              ?.toString(),
          ],
          overrides: {
            from: walletAddress,
            value: 0,
            // gasPrice: 110,
          },
        });
        const approve = res?.wait();
        toast
          .promise(approve, {
            loading: "Token Approval is Pending....",
            success: (d) => {
              return "Token Transaction Approved.";
            },
            error: (error) => {
              return error.message ?? " Token Approval request failed.";
            },
          })
          .then(async (d) => {
            const res = await Stake_Methods(
              walletAddress,
              Number(packageStaking * 1e18).toLocaleString("fullwide", {
                useGrouping: false,
              }),
              refralId
            );
            const stakeToken = res?.wait();
            toast
              .promise(stakeToken, {
                loading: " Transaction is Pending....",
                success: (d) => {
                  return "Transaction Successfully.";
                },
                error: (error) => {
                  return error.message ?? "  Approval request failed.";
                },
              })
              .then((d) => {
                setRegLoading(false);
                dispatch(RefreshSatate(true));
                navigate("/login");
                console.log(d, "m");
              })
              .catch((e) => {
                setRegLoading(false);
                console.log("Error::", e);
              });
          })
          .catch((error) => setRegLoading(false));
      }
    } else {
      toast.error("insufficient  Balance !");
      setRegLoading(false);
    }
  } catch (error) {
    console.log(error);
    setRegLoading(false);
  }
}

async function Buy_Methods(walletAddress, packageStaking) {
  const res = await writeContract({
    mode: "recklesslyUnprepared",
    ...getNewConAddress,
    functionName: "stake",
    args: [packageStaking],
    overrides: {
      from: walletAddress,
      value: 0,
      // gasPrice: 110,
    },
  });
  return res;
}

export async function BuyPackage(
  walletAddress,
  packageStaking,
  setRegLoading,
  setBuyRefresh
) {
  try {
    setRegLoading(true);
    const getBtycPrice = await getTokenPrice(mBTYC_Address);
    const getBalance = await getBalanceUser(walletAddress, mBTYC_Address);
    console.log(walletAddress, packageStaking, mBTYC_Address);
    const res = await readContract({
      address: mBTYC_Address,
      abi: TOKEN_ABI,
      functionName: "allowance",
      args: [walletAddress, New_Contract_Address],
    });

    const allowance = Number(res._hex);
    const allowanceval = Number(
      (Number(packageStaking) / Number(getBtycPrice) + 0.02) * 1e18
    );
    console.log(allowanceval / 1e18, "allowanceval");

    if (getBalance?.formatted >= allowanceval / 1e18) {
      if (
        Number(allowance) >=
        Number(allowanceval.toLocaleString("fullwide", { useGrouping: false }))
      ) {
        const res = await Buy_Methods(
          walletAddress,

          Number(packageStaking * 1e18).toLocaleString("fullwide", {
            useGrouping: false,
          })
        );

        const stakeToken = res?.wait();
        toast
          .promise(stakeToken, {
            loading: " Transaction is Pending....",
            success: (d) => {
              return "Transaction Successfully.";
            },
            error: (error) => {
              return " Transaction request failed.";
            },
          })
          .then((d) => {
            setRegLoading(false);
            setBuyRefresh(true);
          })
          .catch((e) => {
            setRegLoading(false);
            console.log("Error::", e);
          });
      } else {
        const res = await writeContract({
          mode: "recklesslyUnprepared",
          address: mBTYC_Address,
          abi: TOKEN_ABI,
          functionName: "approve",
          args: [
            New_Contract_Address,
            allowanceval
              .toLocaleString("fullwide", { useGrouping: false })
              ?.toString(),
          ],
          overrides: {
            from: walletAddress,
            value: 0,
            // gasPrice: 110,
          },
        });
        const approve = res?.wait();
        toast
          .promise(approve, {
            loading: "Token Approval is Pending....",
            success: (d) => {
              return "Token Transaction Approved.";
            },
            error: (error) => {
              return error.message ?? " Token Approval request failed.";
            },
          })
          .then(async (d) => {
            const res = await Buy_Methods(
              walletAddress,

              Number(packageStaking * 1e18).toLocaleString("fullwide", {
                useGrouping: false,
              })
            );
            const stakeToken = res?.wait();
            toast
              .promise(stakeToken, {
                loading: " Transaction is Pending....",
                success: (d) => {
                  return "Transaction Successfully.";
                },
                error: (error) => {
                  return error.message ?? "  Approval request failed.";
                },
              })
              .then((d) => {
                setRegLoading(false);
                setBuyRefresh(true);
                console.log(d, "m");
              })
              .catch((e) => {
                setRegLoading(false);
                console.log("Error::", e);
              });
          });
        // .catch((error) => setBtnLoader(false));
      }
    } else {
      setRegLoading(false);
      toast.error("low Balance:");
    }
  } catch (error) {
    setRegLoading(false);
    console.log(error);
  }
}

export async function claimRewards(
  walletAddress,
  setRefresh,
  setBtnLoading,
  setClaimReward
) {
  try {
    setBtnLoading(true);
    const res = await writeContract({
      mode: "recklesslyUnprepared",
      ...getNewConAddress,
      functionName: "claim",
      args: [],
      overrides: {
        from: walletAddress,
        value: 0,
        // gasPrice: 110,
      },
    });

    const claimres = res?.wait();
    toast
      .promise(claimres, {
        loading: "Claim reward is Pending....",
        success: (d) => {
          return "Claim reward Success.";
        },
        error: (error) => {
          return error.message ?? " Claim reward request failed.";
        },
      })
      .then((d) => {
        setBtnLoading(false);
        setRefresh(true);
        setClaimReward(0);
        console.log(d, "m");
      })
      .catch((e) => {
        console.log("Error::", e);
        setBtnLoading(false);
      });
  } catch (error) {
    setBtnLoading(false);
    console.log(error);
  }
}

export async function claimStakeToken(
  walletAddress,
  setRefresh,
  setBtnLoading,
  setWithdrawStakeToken
) {
  try {
    setBtnLoading(true);
    const res = await writeContract({
      mode: "recklesslyUnprepared",
      ...getNewConAddress,
      functionName: "withdrawStakedToken",
      args: [],
      overrides: {
        from: walletAddress,
        value: 0,
        // gasPrice: 110,
      },
    });

    const withDrawToken = res?.wait();
    toast
      .promise(withDrawToken, {
        loading: "withraw Token is Pending....",
        success: (d) => {
          return "withraw Token Success.";
        },
        error: (error) => {
          return error.message ?? " withraw Token request failed.";
        },
      })
      .then((d) => {
        setBtnLoading(false);
        setRefresh(true);
        setWithdrawStakeToken(0);
        console.log(d, "m");
      })
      .catch((e) => {
        console.log("Error::", e);
        setBtnLoading(false);
      });
  } catch (error) {
    setBtnLoading(false);
    console.log(error);
  }
}

export async function getGrowthRewards(token_address, address) {
  try {
    const data = await readContract({
      ...getAddress,
      functionName: "getGrowthReward",
      args: [token_address, address],
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getAvailableForWithdrawToken(address) {
  try {
    const data = await readContract({
      ...getNewConAddress,
      functionName: "getAvailableForWithdraw",
      args: [address],
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getStakingRewards(address) {
  try {
    const data = await readContract({
      ...getNewConAddress,
      functionName: "getStakingTotalReward",
      args: [address],
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getAvailableRewardToken(address) {
  try {
    const data = await readContract({
      ...getNewConAddress,
      functionName: "getAvlReward",
      args: [address],
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getTokenLevelIncome(token_address, address) {
  try {
    const data = await readContract({
      ...getAddress,
      functionName: "getTokenInfoByUser",
      args: [address, token_address],
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getUserDirectWithTime(address, direct) {
  try {
    const data = await readContract({
      ...getAddress,
      functionName: "getUserDirectWithTime",
      args: [address, direct],
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getDeployMentTime() {
  try {
    const data = await readContract({
      ...getAddress,
      functionName: "deployTime",
      // args: [address, direct],
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getUserInfo(address) {
  try {
    const data = await readContract({
      ...getNewConAddress,
      functionName: "userinfo",
      args: [address],
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getStakingLength(address) {
  try {
    const data = await readContract({
      ...getNewConAddress,
      functionName: "getStakingLength",
      args: [address],
    });

    return Number(data);
  } catch (error) {
    console.log(error);
  }
}

export async function getStakingDetails(address, length) {
 
  try {
    const data = await readContract({
      ...getNewConAddress,
      functionName: "getStakingDetails",
      args: [address, length],
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getFarmingRewards(address) {
  try {
    const data = await readContract({
      ...getFarmingContract,
      functionName: "getStakingReward",
      args: [address],
    });

    return Number(data / 1e18);
  } catch (error) {
    console.log(error);
  }
}

export async function getFarmingInfo(address) {
  try {
    const data = await readContract({
      ...getFarmingContract,
      functionName: "users",
      args: [address],
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}

async function Farming_Methods(walletAddress, packageamt, duration) {
  const res = await writeContract({
    mode: "recklesslyUnprepared",
    ...getFarmingContract,
    functionName: "stake",
    args: [packageamt, duration],
    overrides: {
      from: walletAddress,
      value: 0,
      // gasPrice: 110,
    },
  });
  return res;
}

export async function FarmingPackage(
  walletAddress,
  packageStaking,
  duration,
  setFarmLoading,
  setBuyRefresh
) {
  try {
    setFarmLoading(true);
    const getBtycPrice = await getBtycRateDollar(TOKEN_ADDRESS);
    const getBalance = await getBalanceUser(walletAddress, TOKEN_ADDRESS);
    console.log(walletAddress, packageStaking, TOKEN_ADDRESS);
    const res = await readContract({
      address: TOKEN_ADDRESS,
      abi: TOKEN_ABI,
      functionName: "allowance",
      args: [walletAddress, Farming_Contract],
    });

    const allowance = Number(res._hex);
    const allowanceval = Number(
      (Number(packageStaking) * Number(getBtycPrice) + 1) * 1e18
    );
    console.log(allowanceval / 1e18, "allowanceval");

    if (getBalance?.formatted >= allowanceval / 1e18) {
      if (
        Number(allowance) >=
        Number(allowanceval.toLocaleString("fullwide", { useGrouping: false }))
      ) {
        const res = await Farming_Methods(
          walletAddress,
          Number(packageStaking * 1e18).toLocaleString("fullwide", {
            useGrouping: false,
          }),
          duration
        );

        const stakeToken = res?.wait();
        toast
          .promise(stakeToken, {
            loading: " Transaction is Pending....",
            success: (d) => {
              return "Transaction Successfully.";
            },
            error: (error) => {
              return " Transaction request failed.";
            },
          })
          .then((d) => {
            setFarmLoading(false);
            setBuyRefresh(true);
          })
          .catch((e) => {
            setFarmLoading(false);
            console.log("Error::", e);
          });
      } else {
        const res = await writeContract({
          mode: "recklesslyUnprepared",
          address: TOKEN_ADDRESS,
          abi: TOKEN_ABI,
          functionName: "approve",
          args: [
            Farming_Contract,
            allowanceval
              .toLocaleString("fullwide", { useGrouping: false })
              ?.toString(),
          ],
          overrides: {
            from: walletAddress,
            value: 0,
            // gasPrice: 110,
          },
        });
        const approve = res?.wait();
        toast
          .promise(approve, {
            loading: "Token Approval is Pending....",
            success: (d) => {
              return "Token Transaction Approved.";
            },
            error: (error) => {
              return error.message ?? " Token Approval request failed.";
            },
          })
          .then(async (d) => {
            const res = await Farming_Methods(
              walletAddress,

              Number(packageStaking * 1e18).toLocaleString("fullwide", {
                useGrouping: false,
              }),
              duration
            );
            const stakeToken = res?.wait();
            toast
              .promise(stakeToken, {
                loading: " Transaction is Pending....",
                success: (d) => {
                  return "Transaction Successfully.";
                },
                error: (error) => {
                  return error.message ?? "  Approval request failed.";
                },
              })
              .then((d) => {
                setFarmLoading(false);
                setBuyRefresh(true);
                console.log(d, "m");
              })
              .catch((e) => {
                setFarmLoading(false);
                console.log("Error::", e);
              });
          });
        // .catch((error) => setBtnLoader(false));
      }
    } else {
      setFarmLoading(false);
      toast.error("low Balance:");
    }
  } catch (error) {
    setFarmLoading(false);
    console.log(error);
  }
}

export async function getBtycRateDollar() {
  try {
    const data = await readContract({
      ...getFarmingContract,
      functionName: "BTYCPrice",
    });

    return Number(1 / (data / 1e18));
  } catch (error) {
    console.log(error);
  }
}

export async function getmBtycRateDollar() {
  try {
    const data = await readContract({
      ...getFarmingContract,
      functionName: "mBTYCPrice",
    });

    return Number(1 / (data / 1e18));
  } catch (error) {
    console.log(error);
  }
}

export async function getBTYCBalanceUser(address, tokenAddr) {
  try {
    const balance = await fetchBalance({
      address: address,
      token: tokenAddr,
    });
    return balance;
  } catch (err) {
    console.log(err);
  }
}

export async function claimFarmRewards(
  walletAddress,
  setClaimReward
) {
  try {
    const res = await writeContract({
      mode: "recklesslyUnprepared",
      ...getFarmingContract,
      functionName: "claim",
      args: [],
      overrides: {
        from: walletAddress,
        value: 0,
        // gasPrice: 110,
      },
    });

    const claimres = res?.wait();
    toast
      .promise(claimres, {
        loading: "Claim reward is Pending....",
        success: (d) => {
          return "Claim reward Success.";
        },
        error: (error) => {
          return error.message ?? " Claim reward request failed.";
        },
      })
      .then((d) => {
        setClaimReward(0);
        console.log(d, "m");
      })
      .catch((e) => {
        console.log("Error::", e);
      });
  } catch (error) {
    console.log(error);
  }
}


export async function getFarmingTotalStake(address) {
  try {
    const data = await readContract({
      ...getFarmingContract,
      functionName: "users",
      args: [address],
    });

    return (data );
  } catch (error) {
    console.log(error);
  }
}

export async function getFutureFarminToken(address) {
  let tokenLength = await readContract({
    ...getFarmingContract,
    functionName: "getStakingLength",
    args: [address],
  });

  var tokenarr = [];
  for (var i = 0; i < Number(tokenLength); i++) {
    tokenarr.push(i);
  }
  

  const addr = tokenarr.map((length, i) => {
    return {
      ...getFarmingContract,
      functionName: "getStakingInfo",
      args: [address, length],
    };
  });
  
  let totalAmount = 0;

  if (addr.length > 0) {
    const data = await multicall({
      contracts: addr,
    });

    // Process the result
    data.forEach((stakingInfo, i) => {
      

      // Accumulate the 'amount' value
      const amount = stakingInfo[0]; // assuming 'amount' is the first value in the tuple
      totalAmount += Number(amount)/1e18;
    });

    //console.log("Total Amount for all positions:", totalAmount);
    return totalAmount
  }
}


async function BuyBackBtycMethod(walletAddress, packageamt) {
  console.log(packageamt,"packageamt")
  const res = await writeContract({
    mode: "recklesslyUnprepared",
    ...getBuyBackBtyc,
    functionName: "lock",
    args: [packageamt],
    overrides: {
      from: walletAddress,
      value: 0,
      // gasPrice: 110,
    },
  });
  return res;
}

export async function getBtycBuyRateDollar() {
  try {
    const data = await readContract({
      ...getBuyBackBtyc,
      functionName: "BTYCPrice",
    });

    return Number(1 / (data / 1e18));
  } catch (error) {
    console.log(error);
  }
}


export async function BuyBackBtyc(
  walletAddress,
  packageStaking,
  setBuyBackLoading,
  setBuyRefresh
) {
  try {
    setBuyBackLoading(true);
    const getBtycPrice = await getBtycBuyRateDollar(TOKEN_ADDRESS);
    const getBalance = await getBalanceUser(walletAddress, TOKEN_ADDRESS);
    console.log(walletAddress, packageStaking, TOKEN_ADDRESS);
    const res = await readContract({
      address: TOKEN_ADDRESS,
      abi: TOKEN_ABI,
      functionName: "allowance",
      args: [walletAddress, Sell_Btyc],
    });

    const allowance = Number(res._hex);
    const allowanceval = Number(
      (Number(packageStaking) * Number(getBtycPrice) + 1) * 1e18
    );
  

    if (getBalance?.formatted >= allowanceval / 1e18) {
      if (
        Number(allowance) >=
        Number(allowanceval.toLocaleString("fullwide", { useGrouping: false }))
      ) {
        const res = await BuyBackBtycMethod(
          walletAddress,
          Number(packageStaking * 1e18).toLocaleString("fullwide", {
            useGrouping: false,
          }),
         
        );

        const stakeToken = res?.wait();
        toast
          .promise(stakeToken, {
            loading: " Transaction is Pending....",
            success: (d) => {
              return "Transaction Successfully.";
            },
            error: (error) => {
              return " Transaction request failed.";
            },
          })
          .then((d) => {
            setBuyBackLoading(false);
            setBuyRefresh(true);
          })
          .catch((e) => {
            setBuyBackLoading(false);
            console.log("Error::", e);
          });
      } else {
        const res = await writeContract({
          mode: "recklesslyUnprepared",
          address: TOKEN_ADDRESS,
          abi: TOKEN_ABI,
          functionName: "approve",
          args: [
            Sell_Btyc,
            allowanceval
              .toLocaleString("fullwide", { useGrouping: false })
              ?.toString(),
          ],
          overrides: {
            from: walletAddress,
            value: 0,
            // gasPrice: 110,
          },
        });
        const approve = res?.wait();
        toast
          .promise(approve, {
            loading: "Token Approval is Pending....",
            success: (d) => {
              return "Token Transaction Approved.";
            },
            error: (error) => {
              return error.message ?? " Token Approval request failed.";
            },
          })
          .then(async (d) => {
            const res = await BuyBackBtycMethod(
              walletAddress,

              Number(packageStaking * 1e18).toLocaleString("fullwide", {
                useGrouping: false,
              }),
            
            );
            const stakeToken = res?.wait();
            toast
              .promise(stakeToken, {
                loading: " Transaction is Pending....",
                success: (d) => {
                  return "Transaction Successfully.";
                },
                error: (error) => {
                  return error.message ?? "  Approval request failed.";
                },
              })
              .then((d) => {
                setBuyBackLoading(false);
                setBuyRefresh(true);
                console.log(d, "m");
              })
              .catch((e) => {
                setBuyBackLoading(false);
                console.log("Error::", e);
              });
          });
        // .catch((error) => setBtnLoader(false));
      }
    } else {
      setBuyBackLoading(false);
      toast.error("low Balance:");
    }
  } catch (error) {
    setBuyBackLoading(false);
    console.log(error);
  }
}

async function BuyBackmBtycMethod(walletAddress, packageamt) {
  console.log(packageamt,"packageamt")
  const res = await writeContract({
    mode: "recklesslyUnprepared",
    ...getBuyBackmBtyc,
    functionName: "lock",
    args: [packageamt],
    overrides: {
      from: walletAddress,
      value: 0,
      // gasPrice: 110,
    },
  });
  return res;
}

export async function getmBtycBuyRateDollar() {
  try {
    const data = await readContract({
      ...getBuyBackmBtyc,
      functionName: "mBTYCPrice",
    });

    return Number(1 / (data / 1e18));
  } catch (error) {
    console.log(error);
  }
}
export async function getmBTYCReturnData(user) {
  try {
    const data = await readContract({
      ...getAddress,
      functionName: "locks",
       args: [user],
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}


export async function BuyBackmBtyc(
  walletAddress,
  packageStaking,
  setBuyBacmBTYCkLoading,
  setBuymBtycRefresh
) {
  try {
    setBuyBacmBTYCkLoading(true);
    const getBtycPrice = await getmBtycBuyRateDollar();
    const getBalance = await getBalanceUser(walletAddress, mBTYC_Address);
   
    const res = await readContract({
      address: mBTYC_Address,
      abi: TOKEN_ABI,
      functionName: "allowance",
      args: [walletAddress, Sell_mBtyc],
    });

    const allowance = Number(res._hex);
    const allowanceval = Number(
      (Number(packageStaking) * Number(getBtycPrice) + 1) * 1e18
    );
  

    if (getBalance?.formatted >= allowanceval / 1e18) {
      if (
        Number(allowance) >=
        Number(allowanceval.toLocaleString("fullwide", { useGrouping: false }))
      ) {
        const res = await BuyBackmBtycMethod(
          walletAddress,
          Number(packageStaking * 1e18).toLocaleString("fullwide", {
            useGrouping: false,
          }),
         
        );

        const stakeToken = res?.wait();
        toast
          .promise(stakeToken, {
            loading: " Transaction is Pending....",
            success: (d) => {
              return "Transaction Successfully.";
            },
            error: (error) => {
              return " Transaction request failed.";
            },
          })
          .then((d) => {
            setBuyBacmBTYCkLoading(false);
            setBuymBtycRefresh(true);
          })
          .catch((e) => {
            setBuyBacmBTYCkLoading(false);
            console.log("Error::", e);
          });
      } else {
        const res = await writeContract({
          mode: "recklesslyUnprepared",
          address: mBTYC_Address,
          abi: TOKEN_ABI,
          functionName: "approve",
          args: [
            Sell_mBtyc,
            allowanceval
              .toLocaleString("fullwide", { useGrouping: false })
              ?.toString(),
          ],
          overrides: {
            from: walletAddress,
            value: 0,
            // gasPrice: 110,
          },
        });
        const approve = res?.wait();
        toast
          .promise(approve, {
            loading: "Token Approval is Pending....",
            success: (d) => {
              return "Token Transaction Approved.";
            },
            error: (error) => {
              return error.message ?? " Token Approval request failed.";
            },
          })
          .then(async (d) => {
            const res = await BuyBackmBtycMethod(
              walletAddress,
              Number(packageStaking * 1e18).toLocaleString("fullwide", {
                useGrouping: false,
              }),
            
            );
            const stakeToken = res?.wait();
            toast
              .promise(stakeToken, {
                loading: " Transaction is Pending....",
                success: (d) => {
                  return "Transaction Successfully.";
                },
                error: (error) => {
                  return error.message ?? "  Approval request failed.";
                },
              })
              .then((d) => {
                setBuyBacmBTYCkLoading(false);
                setBuymBtycRefresh(true);
                console.log(d, "m");
              })
              .catch((e) => {
                setBuyBacmBTYCkLoading(false);
                console.log("Error::", e);
              });
          });
        // .catch((error) => setBtnLoader(false));
      }
    } else {
      setBuyBacmBTYCkLoading(false);
      toast.error("low Balance:");
    }
  } catch (error) {
    setBuyBacmBTYCkLoading(false);
    console.log(error);
  }
}